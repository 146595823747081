import React, { useState, useEffect } from "react";

import "./Incomes.scss";
import { utils, writeFile } from "xlsx";
import HalfList from "../../components/HalfList/HalfList";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import HalfDetails from "../../components/HalfDetails/HalfDetails";
import CustomTextInput from "../../components/CustomTextInput/CustomTextInput";
import CustomNumberInput from "../../components/CustomNumberInput/CustomNumberInput";
import ObjectDropDown from "../../components/ObjectDropDown/ObjectDropDown";
import Button from "../../components/Button/Button";
import { useSelector } from "react-redux";
import { DateRangePicker, Dropdown } from "rsuite";
import DropDown from "../../components/DropDown/DropDown";
import CustomDropDown from "../../components/CustomDropDown/CustomDropDown";
import Modal from "../../components/Modal/Modal";
import ResponseModal from "../../components/ResponseModal/ResponseModal";

// const BE_URL = "http://localhost:8000";
const BE_URL = "https://server.classerp.in";

const Incomes = () => {
  //RBAC code block
  const allPermissions = useSelector((state) => state.permissions);
  const permissions = allPermissions
    .find((p) => p.name === "Finance")
    ?.dropdowns.find((dd) => dd.name === "Incomes")?.permissions;
  //RBAC Code block ends.

  const [selectedItem, setSelectedItem] = useState(null);
  const [date, setDate] = useState("");
  const [category, setCategory] = useState("");
  const [amount, setAmount] = useState("");
  const [gst, setgst] = useState(null);
  const [cgst, setcgst] = useState(null);
  const [sgst, setsgst] = useState(null);
  const [net, setnet] = useState(null);
  const [description, setDescription] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [totalExpense, settotalExpense] = useState("");
  const [receiptUrl, setReceiptUrl] = useState("");
  const [incomes, setincomes] = useState([]);
  const [accounts, setaccounts] = useState([]);
  const [departments, setdepartments] = useState([]);
  const [allincomes, setallincomes] = useState([]);
  const [users, setusers] = useState([]);
  const [vendors, setvendors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [categoryFilter, setcategoryFilter] = useState("All");
  const [departmentFilter, setDepartmentFilter] = useState("All");
  const [accountFilter, setAccountFilter] = useState("All");

  const [msg, setmsg] = useState(null);
  const [responseType, setresponseType] = useState(null);
  const [isResponseOpen, setisResponseOpen] = useState(false);
  const [isAddModalOpen, setisAddModalOpen] = useState(false);

  // Initialize the date range state
  const [dateRange, setDateRange] = useState(() => {
    const today = new Date();
    const lastMonth = new Date(new Date().setMonth(today.getMonth() - 1));
    return [lastMonth, today];
  });

  const handleDateRangeChange = (range) => {
    setDateRange(range);
  };

  useEffect(() => {
    setSelectedItem(null);

    if (dateRange[0] && dateRange[1]) {
      fetchincomes(dateRange[0], dateRange[1]);
    }
  }, [dateRange]);

  useEffect(() => {
    if (selectedItem) {
      setDate(selectedItem.date);
      setCategory(selectedItem.category);
      setAmount(selectedItem.amount);
      setDescription(selectedItem.description);
      setPaymentMethod(selectedItem.paymentMethod);
      setReceiptUrl(selectedItem.receiptUrl);
    }
  }, [selectedItem]);

  useEffect(() => {
    setSelectedItem(null);
    applyFilters();
  }, [categoryFilter, departmentFilter, accountFilter]);

  const applyFilters = () => {
    let filteredincomes = [...allincomes];

    // Apply category filter
    if (categoryFilter && categoryFilter !== "All") {
      filteredincomes = filteredincomes.filter(
        (item) => item.payment_method === categoryFilter
      );
    }
    if (accountFilter && accountFilter !== "All") {
      filteredincomes = filteredincomes.filter(
        (item) => item.account === accountFilter
      );
    }

    // Calculate the total incomes after filtering
    const totalincomes = filteredincomes.reduce(
      (acc, expense) => acc + Number(expense.amount),
      0
    );
    settotalExpense(totalincomes);
    setincomes(filteredincomes); // Update the displayed items based on filters
  };

  const refreshToken = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.post(
        "https://server.classerp.in/refreshToken",
        { token: tokens.refreshToken }
      );

      if (response.data) {
        localStorage.setItem("ERPTokens", JSON.stringify(response.data));
        return response.data.accessToken;
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      alert("Session expired. Please login again.");
      // Navigate to login page if needed
      return null;
    }
  };

  const fetchincomes = async (startDate, endDate) => {
    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      let url = `${BE_URL}/api/incomes?startDate=${startDate}&endDate=${endDate}`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      });
      setincomes(response.data);
      setallincomes(response.data);
      const totalincomes = response.data.reduce(
        (acc, expense) => acc + Number(expense.amount),
        0
      );
      settotalExpense(totalincomes);
    } catch (error) {
      console.error("Error fetching incomes:", error);
      setError("Failed to fetch incomes.");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchcategories = async () => {
    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens")); // Ensure you have tokens stored in localStorage
      const response = await axios.get(
        `${BE_URL}/api/general/categories/Accounts`,
        {
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`,
          },
        }
      );
      setaccounts(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
      // Handle specific errors (e.g., unauthorized, server issues)
      if (error.response && error.response.status === 401) {
        // Handle unauthorized error, e.g., redirect to login or refresh token
        alert("Session expired. Please login again.");
      } else {
        alert("Failed to fetch categories. Please try again later.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchcategories();
  }, []);

  // const handleAdd = async (formData) => {
  //   setIsLoading(true);
  //   setisAddModalOpen(true);
  //   try {
  //     const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
  //     await axios.post(`${BE_URL}/api/incomes`, formData, {
  //       headers: {
  //         Authorization: `Bearer ${tokens.accessToken}`,
  //         "Content-Type": "multipart/form-data", // Important for sending files
  //       },
  //     });
  //     setresponseType("Success");
  //     setmsg("Added Successfully");
  //     setisResponseOpen(true);
  //     fetchincomes(dateRange[0], dateRange[1]);
  //   } catch (error) {
  //     setresponseType("Error");
  //     setmsg("Failed to Add Expense");
  //     setisResponseOpen(true);
  //   } finally {
  //     setIsLoading(false);
  //     setisAddModalOpen(false);
  //   }
  // };

  // const handleDelete = async (expenseId) => {
  //   setIsLoading(true);
  //   try {
  //     const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
  //     await axios.delete(`${BE_URL}/api/incomes/${expenseId}`, {
  //       headers: { Authorization: `Bearer ${tokens.accessToken}` },
  //     });

  //     setSelectedItem(null);

  //     setresponseType("Success");
  //     setmsg("Deleted Successfully");
  //     setisResponseOpen(true);

  //     fetchincomes(); // Refresh list after deleting
  //   } catch (error) {
  //     setresponseType("Error");
  //     setmsg("Failed to delete. Please try again later.");
  //     setisResponseOpen(true);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const downloadExcel = () => {
    setIsLoading(true);
    const ws = utils.json_to_sheet(incomes);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "incomes");

    // Generate Excel file and download
    writeFile(wb, "incomes.xlsx");
    setIsLoading(false);
  };

  return (
    <div className="halfpage">
      {isLoading && <Loader />}
      {permissions?.can_read ? (
        <>
          <Modal
            isOpen={isResponseOpen}
            onClose={() => setisResponseOpen(false)}
          >
            <ResponseModal
              type={responseType}
              msg={msg}
              onClick={() => setisResponseOpen(false)}
            />
          </Modal>
          <div className="halfpage-header">
            <div className="halfpage-header-name">Incomes</div>

            <div className="halfpage-header-filters">
              <div className="buttton">
                <div className="space">&nbsp; </div>
                <Button
                  type={"curved"}
                  text={"Excel"}
                  onClick={downloadExcel}
                />
              </div>
              <div className="halfpage-header-filters-filter">
                <div className="halfpage-header-filters-label">Date Range</div>
                <DateRangePicker
                  format="yyyy-MM-dd"
                  onChange={handleDateRangeChange}
                  cleanable={false}
                  value={dateRange}
                />
              </div>
              <div className="halfpage-header-filters-filter">
                <div className="halfpage-header-filters-label">
                  Payment Method
                </div>
                <DropDown
                  options={[
                    "All",
                    "Razorpay",
                    "Cash",
                    "Bank Transfer",
                    "Demand Draft",
                  ]}
                  value={categoryFilter}
                  onChange={(value) => setcategoryFilter(value)}
                />
              </div>
              <div className="halfpage-header-filters-filter">
                <div className="halfpage-header-filters-label">Account</div>
                <DropDown
                  options={["All", ...accounts.map((item) => item.name)]}
                  value={accountFilter}
                  onChange={(value) => setAccountFilter(value)}
                />
              </div>
            </div>
          </div>
          <div className="halfpage-subheader">
            <div className="halfpage-header-card">
              <div className="halfpage-header-card-title">Total Income </div>
              <div className="halfpage-header-card-number">
                <span>&#8377; {totalExpense}</span>
              </div>
            </div>
          </div>
          <div className="halfpage-contents">
            <div className="halfpage-contents-list">
              <HalfList
                data={incomes}
                fields={["name", "receipt_no", "amount"]}
                setSelectedItem={setSelectedItem}
                can_create={false}
              />
              {error && <p className="error">{error}</p>}
            </div>
            <div className="halfpage-contents-details">
              {selectedItem ? (
                <div>
                  <HalfDetails
                    id={selectedItem?.id}
                    name={selectedItem?.receipt_no}
                    can_delete={permissions?.can_delete}
                    can_update={false}
                    children={
                      <DetailsChild
                        selectedItem={selectedItem}
                        setSelectedItem={setSelectedItem}
                      />
                    } // Define this component for showing expense details and editing
                  />
                </div>
              ) : (
                <div className="halfpage-contents-details-noselection">
                  Select an item to see details
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default Incomes;

const DetailsChild = ({ selectedItem, setSelectedItem }) => {
  // const [file, setFile] = useState(null);
  // const [uploading, setUploading] = useState(false);
  // const [message, setMessage] = useState("");

  const formatDateAndTime = (timestamp) => {
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleDateString(); // e.g., "12/24/2023"
    const formattedTime = date.toLocaleTimeString(); // e.g., "12:32:35 AM"
    return `${formattedDate} ${formattedTime}`;
  };
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleDateString(); // e.g., "12/24/2023"
    return `${formattedDate}`;
  };

  return (
    <div className="dc">
      <div className="dc-text">
        <div className="dc-text-label">Name</div>
        <div className="dc-text-data">{selectedItem.name}</div>
      </div>
      <div className="dc-text">
        <div className="dc-text-label">Pyament Method</div>
        <div className="dc-text-data">{selectedItem.payment_method}</div>
      </div>
      <div className="dc-text">
        <div className="dc-text-label">Installment No</div>
        <div className="dc-text-data"> {selectedItem.installment_number}</div>
      </div>
      <div className="dc-text">
        <div className="dc-text-label">Installment Name</div>
        <div className="dc-text-data">{selectedItem.installment_name}</div>
      </div>
      <div className="dc-text">
        <div className="dc-text-label">Account</div>
        <div className="dc-text-data">{selectedItem.account}</div>
      </div>
      <div className="dc-text">
        <div className="dc-text-label">Amount</div>
        <div className="dc-text-data">
          <strong> &#8377; {selectedItem.amount} </strong>
        </div>
      </div>
      <div className="dc-text">
        <div className="dc-text-label">Payment Date & Time</div>
        <div className="dc-text-data">
          {formatDateAndTime(selectedItem.created_at)}
        </div>
      </div>

      {selectedItem.year && <div className="dc-text"></div>}
      {selectedItem.leaves && (
        <div className="dc-text">
          <div className="dc-text-label">Order Id / Transaction No</div>
          <div className="dc-text-data">&#8377; {selectedItem.order_id}</div>
        </div>
      )}

      <div className="dc-text">
        <div className="dc-text-label">Payment Accepted By</div>
        <div className="dc-text-data">{selectedItem.processor_name}</div>
      </div>
      {/* <div className="dc-text">
        <div className="dc-text-label">Payment Method</div>
        <div className="dc-text-data">{selectedItem.payment_method}</div>
      </div> */}

      {/* <div className="dc-text">
        <div className="dc-text-label">Created At</div>
        <div className="dc-text-data">
          {formatDateAndTime(selectedItem.created_at)}
        </div>
      </div> */}
      {selectedItem.receipt_url ? (
        <div className="dc-text">
          <div className="dc-text-label">Receipt</div>
          <div className="dc-text-data">
            <a target="_blank" href={selectedItem.receipt_url}>
              {selectedItem.receipt_no}
            </a>
          </div>
        </div>
      ) : null}
    </div>
  );
};
