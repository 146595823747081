import "./Footer.scss";

import React from "react";

import { Link } from "react-router-dom";

//import logoIcon from "../../assets/images/logoIcon.svg";
import facebook from "../../assets/images/facebook.png";
import youtube from "../../assets/images/youtube.png";
import email from "../../assets/images/email.png";
import phone from "../../assets/images/phone.png";

function Footer() {
  return (
    <div className="footer">
      <div className="footer-left">
        {/* <img
          className="footer-left-image"
          src={logoIcon}
          alt="Chaitainyas Logo"
        /> */}
        <div className="footer-left-name">
          Krishna Chaitanya Educare Pvt. Ltd. & Chaitanya's Charitable
          Foundation
        </div>
        <div className="footer-left-tabSep"></div>
      </div>

      <div className="footer-seperator seperator1"></div>

      <div className="footer-middle">
        <div className="footer-middle-heading">Follow us on</div>
        <div className="footer-middle-links">
          <Link
            target="_blank"
            to={`https://www.facebook.com/chaitanyasacademy.in`}
            className="footer-middle-links-link"
          >
            <img src={facebook} alt="Facebook" />
            <span>Facebook Chaitainyas</span>
          </Link>
          <Link
            target="_blank"
            to={`https://www.youtube.com/channel/UCRa1e8Yl39eIsz9SpMmTqKw`}
            className="footer-middle-links-link"
          >
            <img src={youtube} alt="Chaitainya's Academy Instagram" />
            <span>YouTube Chaitainyas</span>
          </Link>
        </div>
      </div>

      <div className="footer-seperator"></div>

      <div className="footer-right">
        <div className="footer-right-heading">Contact us at</div>
        <div className="footer-right-links">
          <Link
            target="_blank"
            to={`mailto:info@chaitanyasacademy.in`}
            className="footer-right-links-link"
          >
            <img src={email} alt="Chaitainya's Academy Linkedin" />
            <span>info@chaitanyasacademy.in</span>
          </Link>
          <Link
            target="_blank"
            to={`tel:+917003419670`}
            className="footer-right-links-link"
          >
            <img src={phone} alt="Chaitainya's Academy Instagram" />
            <span>+91 700 341 9670</span>
          </Link>
        </div>
      </div>
      <div className="footer-pages">
        <div className="footer-pages-page">
          <Link to={`./privacypolicy`}>Privacy Policy</Link>
        </div>
        <div className="footer-pages-page">
          <Link to={`./termsconditions`}>Terms & Conditions</Link>
        </div>
        <div className="footer-pages-page">
          <Link to={`./contact`}>Contact Us</Link>
        </div>
        <div className="footer-footer-desc"></div>
      </div>

      <div className="footer-footer">
        <div className="footer-footer-copyright">
          &#169; Copyright 2024 Krishna Chaitanya Educare Pvt. Ltd.
        </div>
        <div className="footer-footer-desc">
          Designed and Developed by{" "}
          <Link target="_blank" to={`https://siddharthrai.com/`}>
            {" "}
            Siddharth Rai
          </Link>
          .
        </div>
      </div>
    </div>
  );
}

export default Footer;
