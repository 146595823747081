import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "./Topbar.scss";
import Button from "../Button/Button";
import user from "../../assets/images/user.jpg";
import logo from "../../assets/images/logo.png";
import { useDispatch } from "react-redux";
import { clearUser } from "../../state/auth/authSlice";
import { jwtDecode } from "jwt-decode";

const Topbar = () => {
  const [menuData, setMenuData] = useState([]);
  const [activeMenu, setActiveMenu] = useState(null);
  const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
  const userrole = jwtDecode(tokens.accessToken).role_id;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const studentMenu = [
    {
      id: 1,
      name: "Home",
      islink: true,
      path: "/student",
      dropdowns: [],
    },
    {
      id: 2,
      name: "LMS",
      islink: false,
      path: "",
      dropdowns: [
        {
          id: 1,
          name: "Timetable",
          path: "/student/timetable",
        },
        {
          id: 2,
          name: "Resources",
          path: "/student/resources",
        },
        {
          id: 3,
          name: "Leaves",
          path: "/student/leaves",
        },
        {
          id: 4,
          name: "Results",
          path: "/student/results",
        },
      ],
    },
  ];

  const fetchMenuData = async () => {
    try {
      const response = await axios.get(
        // "http://localhost:8000/api/menu-data",
        "https://server.classerp.in/api/menu-data",
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );
      const extrasMenuItem = {
        id: 12,
        name: "Extras",
        dropdowns: [
          {
            id: 1,
            name: "Resources",
            path: "/admin/extras/resources",
          },
          {
            id: 2,
            name: "Apply Leave",
            path: "/admin/extras/applyleave",
          },
          {
            id: 2,
            name: "Salary Slips",
            path: "/admin/extras/salarySlips",
          },
        ],
      };

      const dash = {
        id: 1,
        name: "Home",
        islink: true,
        path: "/admin",
        dropdowns: [],
      };

      // Combine the fetched menu data with the Extras menu item
      const updatedMenuData = [dash, ...response.data, extrasMenuItem];
      setMenuData(updatedMenuData);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Implement token refresh logic here
      } else {
        console.error("Error fetching menu data", error);
      }
    }
  };

  useEffect(() => {
    if (userrole === 10) {
      setMenuData(studentMenu);
    } else {
      fetchMenuData();
    }
  }, []);

  const toggleMenu = (menuName) => {
    setActiveMenu(activeMenu === menuName ? null : menuName);
  };

  const handleLogout = () => {
    localStorage.removeItem("ERPTokens");
    dispatch(clearUser());
    navigate("/login");
  };

  const navigateProfile = () => {
    navigate("/profile");
  };

  return (
    <div className="topbar">
      <div className="topbar-logo">
        <img src={logo} alt="Chaitanyas Academy Logo" />
      </div>

      <div className="topbar-contents">
        {menuData.map((menuItem) => (
          <div
            key={menuItem.id}
            className="topbar-item"
            onClick={
              menuItem.islink
                ? () => navigate(menuItem.path)
                : () => toggleMenu(menuItem.name)
            }
          >
            {menuItem.name}
            {activeMenu === menuItem.name && (
              <div className="dropdown-menu">
                {menuItem.dropdowns.map((dd) => (
                  <Link key={dd.id} to={dd.path}>
                    {dd.name}
                  </Link>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>

      <div className="topbar-user" onClick={navigateProfile}>
        <img src={user} alt="user" />
      </div>
    </div>
  );
};

export default Topbar;
