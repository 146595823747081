import React, { useState, useEffect } from "react";
import HalfList from "../../components/HalfList/HalfList";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import HalfDetails from "../../components/HalfDetails/HalfDetails";
import CustomTextInput from "../../components/CustomTextInput/CustomTextInput";
import Button from "../../components/Button/Button";
import { useSelector } from "react-redux";
import CustomNumberInput from "../../components/CustomNumberInput/CustomNumberInput";
import ObjectDropDown from "../../components/ObjectDropDown/ObjectDropDown";

import Modal from "../../components/Modal/Modal";
import ResponseModal from "../../components/ResponseModal/ResponseModal";
import DropDown from "../../components/DropDown/DropDown";
import { ComponentsDisplay } from "../../components/InstallmentsDisplay/InstallmentsDisplay";

// const BE_URL = "http://localhost:8000";
const BE_URL = "https://server.classerp.in";

const Installments = () => {
  //RBAC code block
  const allPermissions = useSelector((state) => state.permissions);
  const permissions = allPermissions
    .find((p) => p.name === "Management")
    ?.dropdowns.find((dd) => dd.name === "Installments")?.permissions;
  //RBAC Code block ends.

  const [selectedItem, setSelectedItem] = useState(null);
  const [name, setname] = useState(null);
  const [fee, setfee] = useState(null);
  const [account_id, setaccount_id] = useState(null);
  const [account_name, setaccount_name] = useState(null);

  const [accounts, setaccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [installments, setInstallments] = useState([]);
  const [components, setcomponents] = useState([]);

  const [msg, setmsg] = useState(null);
  const [responseType, setresponseType] = useState(null);
  const [isResponseOpen, setisResponseOpen] = useState(false);
  const [isAddModalOpen, setisAddModalOpen] = useState(false);

  // Fetch classes from API
  useEffect(() => {
    fetchinstallments();
    fetchcategories();
  }, []);

  useEffect(() => {
    if (selectedItem) {
      setname(selectedItem.name);
      setfee(selectedItem.amount);
      setaccount_id(selectedItem.account_id);
      setaccount_name(selectedItem.account_name);
      setcomponents(selectedItem.components || []);
    }
  }, [selectedItem]);

  const refreshToken = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.post(
        "https://server.classerp.in/refreshToken",
        { token: tokens.refreshToken }
      );

      if (response.data) {
        localStorage.setItem("ERPTokens", JSON.stringify(response.data));
        return response.data.accessToken;
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      alert("Session expired. Please login again.");
      // Navigate to login page if needed
      return null;
    }
  };

  const fetchinstallments = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.get(
        `${BE_URL}/api/feestructure/installments`,
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );

      setInstallments(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return setInstallments(); // Retry fetching classes with new token
        } else {
          // Redirect to login or show an error
          setError("Authentication failed. Please log in again.");
        }
      } else {
        setError(error.message || "Failed to fetch Installments.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchcategories = async () => {
    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens")); // Ensure you have tokens stored in localStorage
      const response = await axios.get(
        `${BE_URL}/api/general/categories/Accounts`,
        {
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`,
          },
        }
      );
      setaccounts(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
      // Handle specific errors (e.g., unauthorized, server issues)
      if (error.response && error.response.status === 401) {
        // Handle unauthorized error, e.g., redirect to login or refresh token
        alert("Session expired. Please login again.");
      } else {
        alert("Failed to fetch categories. Please try again later.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleAdd = async (newInstallment) => {
    setIsLoading(true);
    setisAddModalOpen(true);
    setError(null);

    console.log(newInstallment);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));

      const response = await axios.post(
        `${BE_URL}/api/feestructure/installments`,
        newInstallment,
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );

      // alert("Added Successfully");
      setresponseType("Success");
      setmsg("Added Successfully");
      setisResponseOpen(true);

      fetchinstallments();
      setSelectedItem(null);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return handleAdd(newInstallment);
        } else {
          alert("Authentication failed. Please log in again.");
        }
      } else {
        setresponseType("Error");
        setmsg("Failed to Add Fee Structure");
        setisResponseOpen(true);
      }
    } finally {
      setisAddModalOpen(false);
      setIsLoading(false);
    }
  };

  const handleEdit = async () => {
    setIsLoading(true);
    setError(null);

    const id = selectedItem.id;

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.put(
        `${BE_URL}/api/feestructure/installments/${id}`,
        {
          name,
          amount: fee,
          account_id,
          components,
        },
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );
      setresponseType("Success");
      setmsg("Updated Successfully");
      setisResponseOpen(true);

      fetchinstallments();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return handleEdit(); // Retry editing subject with new token
        } else {
          alert("Authentication failed. Please log in again.");
        }
      } else {
        setresponseType("Error");
        setmsg("Failed to edit. Please try again later.");
        setisResponseOpen(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (id) => {
    setIsLoading(true);
    setError(null);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      await axios.delete(
        `${BE_URL}/api/feestructure/installments/${selectedItem.id}`,
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );

      setSelectedItem(null);

      setresponseType("Success");
      setmsg("Deleted Successfully");
      setisResponseOpen(true);

      fetchinstallments();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return handleDelete(id);
        } else {
          alert("Authentication failed. Please log in again.");
        }
      } else {
        setresponseType("Error");
        setmsg("Failed to delete Fee Structure");
        setisResponseOpen(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="classes">
      {isLoading && <Loader />}
      {permissions?.can_read ? (
        <>
          <Modal
            isOpen={isResponseOpen}
            onClose={() => setisResponseOpen(false)}
          >
            <ResponseModal
              type={responseType}
              msg={msg}
              onClick={() => setisResponseOpen(false)}
            />
          </Modal>
          <div className="classes-header">
            <div className="classes-header-name">Installments</div>
          </div>
          <div className="classes-contents">
            <div className="classes-contents-list">
              <HalfList
                data={installments}
                fields={["name", "amount"]}
                setSelectedItem={setSelectedItem}
                isAddModalOpen={isAddModalOpen}
                can_create={permissions?.can_create}
                elem={
                  <AddInstallment
                    handleAdd={(newInstallment) => handleAdd(newInstallment)}
                    accounts={accounts}
                  />
                }
              />
              {error && <p className="error">{error}</p>}
            </div>
            <div className="classes-contents-details">
              {selectedItem ? (
                <div>
                  <HalfDetails
                    id={selectedItem.id}
                    name={selectedItem.name}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    can_delete={permissions?.can_delete}
                    can_update={permissions?.can_update}
                  >
                    <DetailsChild
                      id={selectedItem.id}
                      name={name}
                      fee={fee}
                      account_id={account_id}
                      components={components}
                      account_name={account_name}
                      setaccount_name={setaccount_name}
                      setaccount_id={setaccount_id}
                      setname={setname}
                      setfee={setfee}
                      setcomponents={setcomponents}
                      accounts={accounts}
                    />
                  </HalfDetails>
                </div>
              ) : (
                <div className="classes-contents-details-noselection">
                  Select an item to see details
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default Installments;

const AddInstallment = ({ handleAdd, classes, accounts }) => {
  const [name, setname] = useState(null);
  const [fee, setfee] = useState(null);
  const [account_id, setaccount_id] = useState(null);
  const [account, setaccount] = useState(null);

  useEffect(() => {
    let newAccId = null;
    const newAc = accounts && accounts.filter((acc) => acc.name === account)[0];
    newAccId = newAc && newAc.id;
    // console.log(newAccId[0].id);
    setaccount_id(newAccId);
  }, [account]);

  // State for installment details
  const [components, setcomponents] = useState([]);

  const handleSubmit = () => {
    console.log(account_id);
    const newInstallment = {
      amount: fee,
      name,
      account_id,
      components: components ? components : [],
    };
    handleAdd(newInstallment);
  };

  return (
    <div className="halfAdd">
      <div className="halfAdd-title">Add an Installment</div>
      <DropDown
        label="Account"
        options={accounts.map((acc) => acc.name)}
        value={account}
        onChange={setaccount}
      />
      <CustomTextInput
        label="Name"
        placeholder="Name"
        value={name}
        onChange={(e) => setname(e.target.value)}
      />
      <CustomNumberInput
        value={fee}
        label={`Amount`}
        placeholder={`Amount`}
        onChange={(e) => setfee(e)}
      />

      {/* Installments Display/Edit Component */}
      <ComponentsDisplay
        title="Components"
        fee={fee}
        installments={components}
        setInstallments={setcomponents}
        accounts={accounts}
      />

      <Button text="Add Installment" onClick={handleSubmit} />
    </div>
  );
};

const DetailsChild = ({
  id,
  name,
  fee,
  account_id,

  components,
  setname,
  setfee,
  setaccount_id,
  setcomponents,
  accounts,
}) => {
  const [account, setaccount] = useState(null);

  useEffect(() => {
    const accountobj = accounts.filter((acc) => acc.id === account_id);
    const accName = accountobj[0] && accountobj[0].name;
    setaccount(accName);
  }, [account_id]);

  return (
    <div className="dc">
      {/* <h2>Fee Structure Details</h2> */}
      {/* Class ID and other non-boolean details remain the same */}

      <div className="dc-input">
        <CustomTextInput
          label="Name"
          value={name}
          onChange={(e) => setname(e.target.value)}
        />
      </div>
      <div className="dc-input">
        <CustomNumberInput
          value={fee}
          label={`Amount`}
          placeholder={`Amount`}
          onChange={(e) => setfee(e)}
        />
      </div>

      <div className="dc-input">
        <DropDown
          label="Account"
          options={accounts.map((acc) => acc.name)}
          value={account}
          onChange={setaccount}
        />
      </div>

      {/* Installments Display/Edit Component */}
      <ComponentsDisplay
        title="Components"
        fee={fee}
        installments={components}
        setInstallments={setcomponents}
        accounts={accounts}
      />
    </div>
  );
};
