import React from "react";
import "./EntranceTable.scss";
import { useNavigate } from "react-router-dom";

const EntranceTable = ({ data, navigateBaseUrl }) => {
  const navigate = useNavigate();

  const formatDateAndTime = (timestamp) => {
    const date = new Date(timestamp);

    // Manually extract day, month, and year for custom formatting
    const day = String(date.getDate()).padStart(2, "0"); // Ensure 2 digits for day
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so we add 1
    const year = date.getFullYear();

    const formattedDate = `${day}/${month}/${year}`; // Format as dd/mm/yyyy
    const formattedTime = date.toLocaleTimeString(); // e.g., "12:32:35 AM"

    return `${formattedDate} ${formattedTime}`;
  };

  return (
    <div className="entrance-table">
      {data.map((item) => (
        <div
          key={item.id}
          className={`entrance-row ${item.percent ? "entrance-row-green" : ""}`}
          onClick={() => navigate(`${navigateBaseUrl}/${item.roll_no}`)}
        >
          <div className="row-id">{item.roll_no}</div>
          <div className="row-name">{`${item.first_name} ${item.middle_name} ${item.last_name}`}</div>
          <div className="row-whatsapp">{item.aadhar}</div>
          <div className="row-class">{item.adm_class}</div>
          <div className="row-timestamp">
            {formatDateAndTime(item.timestamp)}
          </div>
        </div>
      ))}
    </div>
  );
};

export default EntranceTable;
