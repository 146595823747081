import React, { useState, useEffect } from "react";

import "./Expenses.scss";
import { utils, writeFile } from "xlsx";
import HalfList from "../../components/HalfList/HalfList";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import HalfDetails from "../../components/HalfDetails/HalfDetails";
import CustomTextInput from "../../components/CustomTextInput/CustomTextInput";
import CustomNumberInput from "../../components/CustomNumberInput/CustomNumberInput";
import ObjectDropDown from "../../components/ObjectDropDown/ObjectDropDown";
import Button from "../../components/Button/Button";
import { useSelector } from "react-redux";
import { DateRangePicker, Dropdown } from "rsuite";
import DropDown from "../../components/DropDown/DropDown";
import CustomDropDown from "../../components/CustomDropDown/CustomDropDown";
import Modal from "../../components/Modal/Modal";
import ResponseModal from "../../components/ResponseModal/ResponseModal";

// const BE_URL = "http://localhost:8000";
const BE_URL = "https://server.classerp.in";

const Expenses = () => {
  //RBAC code block
  const allPermissions = useSelector((state) => state.permissions);
  const permissions = allPermissions
    .find((p) => p.name === "Finance")
    ?.dropdowns.find((dd) => dd.name === "Expenses")?.permissions;
  const expApprover = allPermissions
    .find((p) => p.name === "Finance")
    ?.dropdowns.find((dd) => dd.name === "Expense Approver")?.permissions;
  //RBAC Code block ends.

  const [selectedItem, setSelectedItem] = useState(null);
  const [date, setDate] = useState("");
  const [category, setCategory] = useState("");
  const [amount, setAmount] = useState("");
  const [is_approved, setis_approved] = useState(null);
  const [cgst, setcgst] = useState(null);
  const [sgst, setsgst] = useState(null);
  const [net, setnet] = useState(null);
  const [description, setDescription] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [totalExpense, settotalExpense] = useState("");
  const [receiptUrl, setReceiptUrl] = useState("");
  const [file, setFile] = useState(null);

  const [status, setStatus] = useState(null);
  const [transaction_no, setTransaction_no] = useState(null);
  const [expenses, setExpenses] = useState([]);
  const [accounts, setaccounts] = useState([]);
  const [departments, setdepartments] = useState([]);
  const [allexpenses, setallexpenses] = useState([]);
  const [users, setusers] = useState([]);
  const [vendors, setvendors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [statusFilter, setstatusFilter] = useState("All");
  const [categoryFilter, setcategoryFilter] = useState("All");
  const [departmentFilter, setDepartmentFilter] = useState("All");
  const [accountFilter, setAccountFilter] = useState("All");

  const [msg, setmsg] = useState(null);
  const [responseType, setresponseType] = useState(null);
  const [isResponseOpen, setisResponseOpen] = useState(false);
  const [isAddModalOpen, setisAddModalOpen] = useState(false);

  const today = new Date();
  const oneYearAgo = new Date(new Date().setFullYear(today.getFullYear() - 1));
  const oneWeekAgo = new Date(new Date().setDate(today.getDate() - 7));
  const oneMonthAgo = new Date(new Date().setMonth(today.getMonth() - 1));

  // Initialize the date range state
  const [dateRange, setDateRange] = useState([oneYearAgo, today]);

  useEffect(() => {
    fetchUsers();
    fetchcategories();
  }, []);

  const handleDateRangeChange = (range) => {
    setDateRange(range);
  };

  useEffect(() => {
    setSelectedItem(null);

    if (dateRange[0] && dateRange[1]) {
      fetchExpenses(dateRange[0], dateRange[1]);
    }
  }, [dateRange]);

  useEffect(() => {
    if (selectedItem) {
      setDate(selectedItem.date);
      setCategory(selectedItem.category);
      setAmount(selectedItem.amount);
      setDescription(selectedItem.description);
      setPaymentMethod(selectedItem.paymentMethod);
      setReceiptUrl(selectedItem.receiptUrl);
      setStatus(selectedItem.status || null);
      setTransaction_no(selectedItem.transaction_no || null);
      setis_approved(selectedItem.is_approved);
      setFile(null);
    }
  }, [selectedItem]);

  useEffect(() => {
    setSelectedItem(null);
    applyFilters();
  }, [categoryFilter, departmentFilter, accountFilter, statusFilter]);

  const applyFilters = () => {
    let filteredExpenses = [...allexpenses];

    console.log("Account filter is ", accountFilter);

    // Apply category filter
    if (categoryFilter && categoryFilter !== "All") {
      filteredExpenses = filteredExpenses.filter(
        (item) => item.category === categoryFilter
      );
    }
    // Apply status filter
    if (statusFilter && statusFilter !== "All") {
      filteredExpenses = filteredExpenses.filter(
        (item) => item.status === statusFilter
      );
    }

    // Apply department filter
    if (departmentFilter && departmentFilter !== "All") {
      filteredExpenses = filteredExpenses.filter(
        (item) => item.department === departmentFilter
      );
    }

    // Apply account filter
    if (accountFilter && accountFilter !== "All") {
      filteredExpenses = filteredExpenses.filter(
        (item) => item.account === accountFilter
      );
    }

    // Calculate the total expenses after filtering
    const totalExpenses = filteredExpenses.reduce(
      (acc, expense) => acc + Number(expense.amount),
      0
    );

    // Round to two decimal places
    const roundedTotalExpenses = parseFloat(totalExpenses.toFixed(2));

    settotalExpense(roundedTotalExpenses);
    console.log(roundedTotalExpenses);
    setExpenses(filteredExpenses); // Update the displayed items based on filters
  };

  const refreshToken = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.post(
        "https://server.classerp.in/refreshToken",
        { token: tokens.refreshToken }
      );

      if (response.data) {
        localStorage.setItem("ERPTokens", JSON.stringify(response.data));
        return response.data.accessToken;
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      alert("Session expired. Please login again.");
      // Navigate to login page if needed
      return null;
    }
  };

  const fetchcategories = async () => {
    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens")); // Ensure you have tokens stored in localStorage
      const response = await axios.get(
        `${BE_URL}/api/general/categories/Accounts`,
        {
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`,
          },
        }
      );
      setaccounts(response.data);

      const responseDept = await axios.get(
        `${BE_URL}/api/general/categories/Departments`,
        {
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`,
          },
        }
      );
      setdepartments(responseDept.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
      // Handle specific errors (e.g., unauthorized, server issues)
      if (error.response && error.response.status === 401) {
        // Handle unauthorized error, e.g., redirect to login or refresh token
        alert("Session expired. Please login again.");
      } else {
        alert("Failed to fetch categories. Please try again later.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.get(`${BE_URL}/api/general/getAllUsers`, {
        headers: {
          Authorization: `Bearer ${tokens.accessToken}`,
        },
      });
      setusers(response.data);

      const responseVendors = await axios.get(
        `${BE_URL}/api/general/getAllVendors`,
        {
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`,
          },
        }
      );
      setvendors(responseVendors.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Token expired, refresh token
        const newTokens = await refreshToken();
        if (newTokens) {
          return fetchUsers(); // Retry fetching users with new token
        } else {
          alert("Session expired. Please login again.");
        }
      } else {
        console.error("Error fetching users:", error);
        alert("Failed to fetch user data. Please try again.");
      }
    }
    setIsLoading(false);
  };

  const fetchExpenses = async (startDate, endDate) => {
    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      let url = `${BE_URL}/api/expenses?startDate=${startDate}&endDate=${endDate}`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      });
      setExpenses(response.data);
      setallexpenses(response.data);
      const totalExpenses = response.data.reduce(
        (acc, expense) => acc + Number(expense.amount),
        0
      );
      settotalExpense(totalExpenses.toFixed(2));
    } catch (error) {
      console.error("Error fetching expenses:", error);
      setError("Failed to fetch expenses.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleAdd = async (formData) => {
    setIsLoading(true);
    setisAddModalOpen(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      await axios.post(`${BE_URL}/api/expenses`, formData, {
        headers: {
          Authorization: `Bearer ${tokens.accessToken}`,
          "Content-Type": "multipart/form-data", // Important for sending files
        },
      });
      setresponseType("Success");
      setmsg("Added Successfully");
      setisResponseOpen(true);
      fetchExpenses(dateRange[0], dateRange[1]);
    } catch (error) {
      setresponseType("Error");
      setmsg("Failed to Add Expense");
      setisResponseOpen(true);
    } finally {
      setIsLoading(false);
      setisAddModalOpen(false);
    }
  };

  const updateExpense = async () => {
    setIsLoading(true);
    window.scrollTo(0, 0);
    // if (!file) {
    //   setMessage("No file selected for upload.");
    //   return;
    // }

    const formData = new FormData();
    if (file) {
      formData.append("receipt", file);
    }
    formData.append("status", status);
    formData.append("transaction_no", transaction_no);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.patch(
        `${BE_URL}/api/expenses/${selectedItem.id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // alert("Updated successfully!");
      setresponseType("Success");
      setmsg("Updated Successfully");
      setisResponseOpen(true);
      fetchExpenses(dateRange[0], dateRange[1]);
    } catch (error) {
      setresponseType("Error");
      setmsg("Failed to update Expense");
      setisResponseOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (expenseId) => {
    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      await axios.delete(`${BE_URL}/api/expenses/${expenseId}`, {
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      });

      setSelectedItem(null);

      setresponseType("Success");
      setmsg("Deleted Successfully");
      setisResponseOpen(true);

      fetchExpenses(dateRange[0], dateRange[1]); // Refresh list after deleting
    } catch (error) {
      setresponseType("Error");
      setmsg("Failed to delete. Please try again later.");
      setisResponseOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const downloadExcel = () => {
    setIsLoading(true);
    const ws = utils.json_to_sheet(expenses);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Expenses");

    // Generate Excel file and download
    writeFile(wb, "Expenses.xlsx");
    setIsLoading(false);
  };

  return (
    <div className="halfpage">
      {isLoading && <Loader />}
      {permissions?.can_read ? (
        <>
          <Modal
            isOpen={isResponseOpen}
            onClose={() => setisResponseOpen(false)}
          >
            <ResponseModal
              type={responseType}
              msg={msg}
              onClick={() => setisResponseOpen(false)}
            />
          </Modal>
          <div className="halfpage-header">
            <div className="halfpage-header-name">Expenses</div>
            <div className="halfpage-header-filters">
              <div className="halfpage-header-filters-filter">
                <div className="halfpage-header-filters-label">Date Range</div>
                <DateRangePicker
                  format="yyyy-MM-dd"
                  onChange={handleDateRangeChange}
                  cleanable={false}
                  value={dateRange}
                  ranges={[
                    {
                      label: "Last 1 Week",
                      value: [oneWeekAgo, today],
                    },
                    {
                      label: "Last 1 Month",
                      value: [oneMonthAgo, today],
                    },
                  ]}
                />
              </div>

              <div className="halfpage-header-filters-filter">
                <div className="halfpage-header-filters-label">Department</div>
                <DropDown
                  options={["All", ...departments.map((dep) => dep.name)]}
                  value={departmentFilter}
                  onChange={(value) => setDepartmentFilter(value)}
                />
              </div>
              <div className="halfpage-header-filters-filter">
                <div className="halfpage-header-filters-label">Account</div>
                <DropDown
                  options={["All", ...accounts.map((acc) => acc.name)]}
                  value={accountFilter}
                  onChange={(value) => setAccountFilter(value)}
                />
              </div>
            </div>
          </div>
          <div className="halfpage-subheader">
            <div className="halfpage-header-card">
              <div className="halfpage-header-card-title">Total Expense </div>
              <div className="halfpage-header-card-number">
                <span>&#8377; {totalExpense}</span>
              </div>
            </div>
            <div className="halfpage-header-filters">
              <div className="buttton">
                <div className="space">&nbsp; </div>
                <Button
                  type={"curved"}
                  text={"Excel"}
                  onClick={downloadExcel}
                />
              </div>
              <div className="halfpage-header-filters-filter">
                <div className="halfpage-header-filters-label">Status</div>
                <DropDown
                  options={["All", "Pending", "Done"]}
                  value={statusFilter}
                  onChange={(value) => setstatusFilter(value)}
                />
              </div>
              <div className="halfpage-header-filters-filter">
                <div className="halfpage-header-filters-label">Category</div>
                <DropDown
                  options={[
                    "All",
                    "Vendors",
                    "Staff Payment",
                    "Salary",
                    "Others",
                  ]}
                  value={categoryFilter}
                  onChange={(value) => setcategoryFilter(value)}
                />
              </div>
            </div>
          </div>
          <div className="halfpage-contents">
            <div className="halfpage-contents-list">
              <HalfList
                data={expenses}
                fields={["name", "category", "amount"]}
                setSelectedItem={setSelectedItem}
                handleAdd={handleAdd}
                isAddModalOpen={isAddModalOpen}
                can_create={permissions?.can_create}
                elem={
                  <AddExpense
                    handleAdd={handleAdd}
                    users={users}
                    vendors={vendors}
                    accounts={accounts}
                    departments={departments}
                  />
                } // Define this component with fields for adding expenses
              />
              {error && <p className="error">{error}</p>}
            </div>
            <div className="halfpage-contents-details">
              {selectedItem ? (
                <div>
                  <HalfDetails
                    id={selectedItem?.id}
                    name={selectedItem?.name}
                    handleDelete={() => handleDelete(selectedItem?.id)}
                    handleEdit={updateExpense}
                    can_delete={permissions?.can_delete}
                    can_update={permissions?.can_update}
                    children={
                      <DetailsChild
                        selectedItem={selectedItem}
                        setSelectedItem={setSelectedItem}
                        fetchExpenses={fetchExpenses}
                        status={status}
                        transaction_no={transaction_no}
                        is_approved={is_approved}
                        setis_approved={setis_approved}
                        setStatus={setStatus}
                        setTransaction_no={setTransaction_no}
                        setFile={setFile}
                        expApprover={expApprover}
                      />
                    } // Define this component for showing expense details and editing
                  />
                </div>
              ) : (
                <div className="halfpage-contents-details-noselection">
                  Select an item to see details
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default Expenses;

const AddExpense = ({ handleAdd, users, vendors, accounts, departments }) => {
  const [date, setDate] = useState("");
  const [category, setCategory] = useState("");
  const [amount, setAmount] = useState("");
  const [gst, setgst] = useState(false);
  const [gst_percent, setgst_percent] = useState(null);
  const [cgst, setcgst] = useState(null);
  const [sgst, setsgst] = useState(null);
  const [pt, setpt] = useState(null);
  const [pf, setpf] = useState(null);
  const [tds, settds] = useState(false);
  const [tds_percent, settds_percent] = useState(null);
  const [tds_amount, settds_amount] = useState(null);
  const [net, setnet] = useState(null);
  const [payee_id, setpayee_id] = useState(null);
  const [department, setdepartment] = useState(null);
  const [account, setaccount] = useState(null);
  const [payee_Name, setpayee_Name] = useState(null);
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [transaction_no, settransaction_no] = useState("");
  const [file, setFile] = useState(null);

  useEffect(() => {
    setnet(amount);
    if ((gst && gst_percent && amount) || (tds && tds_percent && amount)) {
      // First calculate GST exclusive amount if GST is applicable
      let gstExclusiveAmount = amount;
      let gstAmount = 0;
      let cgst = 0;
      let sgst = 0;
      let basicAmount = amount;

      if (gst && gst_percent) {
        gstExclusiveAmount = parseFloat(
          (amount / (1 + gst_percent / 100)).toFixed(2)
        );
        gstAmount = parseFloat(
          (gstExclusiveAmount * (gst_percent / 100)).toFixed(2)
        );
        basicAmount = amount - gstAmount;
        cgst = parseFloat((gstAmount / 2).toFixed(2));
        sgst = parseFloat((gstAmount / 2).toFixed(2));
      }

      // Now calculate TDS on the original amount
      let tdsAmount = 0;
      if (tds && tds_percent) {
        tdsAmount = parseFloat((basicAmount * (tds_percent / 100)).toFixed(2));
      }

      // Set all the state values
      setcgst(cgst);
      setsgst(sgst);
      settds_amount(tdsAmount);

      // Net amount after GST and TDS
      let netAmount = parseFloat((amount - tdsAmount).toFixed(2));
      console.log("Net amount is ", netAmount);
      setnet(netAmount);
    }
  }, [gst, gst_percent, tds, tds_percent, amount]);

  const handleSubmit = () => {
    if (category === "Others" && !payee_Name) {
      alert("Please add a payee name!");
      return;
    }
    if (category !== "Others" && !payee_id) {
      alert("Please add a payee!");
      return;
    }
    if (!date || !category || !amount || !paymentMethod || !status) {
      alert(
        "Date, category, Payee, payment method, status and amount are mandatory!"
      );
      return;
    }

    const formData = new FormData();
    formData.append("date", date);
    formData.append("category", category);
    formData.append("department", department);
    formData.append("account", account);
    formData.append("payee_id", payee_id);
    formData.append("payee_Name", payee_Name);
    formData.append("amount", amount);
    formData.append("gst", gst);
    formData.append("gst_percent", gst_percent ? gst_percent : 0);
    formData.append("cgst", cgst ? cgst : 0);
    formData.append("sgst", sgst ? sgst : 0);
    formData.append("pt", pt ? pt : 0);
    formData.append("pf", pf ? pf : 0);
    formData.append("tds", tds ? tds : 0);
    formData.append("tds_percent", tds_percent ? tds_percent : 0);
    formData.append("tds_amount", tds_amount ? tds_amount : 0);
    formData.append("net", net);
    formData.append("description", description);
    formData.append("paymentMethod", paymentMethod);
    formData.append("transaction_no", transaction_no);
    formData.append("status", status);
    formData.append("payee_name", payee_Name);
    if (file) formData.append("receipt", file);

    handleAdd(formData); // Pass formData instead of JSON object
  };

  console.log("Vendors are ", vendors);
  console.log("users are ", users);

  return (
    <div className="halfAdd">
      <h3>Add Expense</h3>
      <CustomTextInput
        label="Date"
        type="date"
        value={date}
        onChange={(e) => setDate(e.target.value)}
      />
      <DropDown
        options={["Vendors", "Staff Payment", "Others"]}
        label="Category"
        value={category}
        onChange={setCategory}
      />

      {category && category !== "Others" && (
        <>
          <CustomDropDown
            data={category === "Vendors" ? vendors : users}
            searchable={true}
            label="Select Payee"
            placeholder="Select"
            onChange={setpayee_id}
            value={payee_id}
          />
        </>
      )}
      {category && category === "Others" && (
        <>
          <CustomTextInput
            label="Payee Name"
            value={payee_Name}
            onChange={(e) => setpayee_Name(e.target.value)}
          />
        </>
      )}
      <DropDown
        options={departments.map((item) => item.name)}
        label="Department"
        value={department}
        onChange={setdepartment}
      />
      <DropDown
        options={accounts.map((item) => item.name)}
        label="Account"
        value={account}
        onChange={setaccount}
      />
      <CustomTextInput
        placeholder="Description"
        label="Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <DropDown
        label="Payment Method"
        options={["Cash", "UPI", "Card payment", "Bank Transfer"]}
        value={paymentMethod}
        onChange={setPaymentMethod}
      />
      <DropDown
        label="Status"
        options={["Done", "Pending"]}
        value={status}
        onChange={setStatus}
      />

      <CustomNumberInput
        placeholder="Amount"
        label="Amount"
        value={amount}
        onChange={(e) => setAmount(e)}
      />
      <DropDown
        label="GST Applicable"
        options={["Yes", "No"]}
        value={gst ? "Yes" : "No"}
        onChange={(val) => {
          val === "Yes" ? setgst(true) : setgst(false);
        }}
      />
      {gst && (
        <DropDown
          options={[5, 12, 18, 28]}
          label="GST Percent"
          value={gst_percent}
          onChange={setgst_percent}
        />
      )}
      {category !== "Staff Payment" && gst && gst_percent && amount && (
        <>
          {/* <div className="info">CGST - &#8377;{cgst}</div> */}
          <CustomNumberInput
            placeholder="CGST"
            label="CGST"
            value={cgst}
            onChange={(e) => setcgst(e)}
          />
          <CustomNumberInput
            placeholder="SGST"
            label="SGST"
            value={sgst}
            onChange={(e) => setsgst(e)}
          />
          <CustomNumberInput
            placeholder="Net (Payable to Vendor)"
            label="Net (Payable to Vendor)"
            value={net}
            onChange={(e) => setnet(e)}
          />
          {/* <div className="info">SGST - &#8377;{sgst}</div>
          <div className="info">Net (Payable to verdor) - &#8377;{net}</div> */}
        </>
      )}

      <DropDown
        label="TDS Applicable"
        options={["Yes", "No"]}
        value={tds ? "Yes" : "No"}
        onChange={(val) => {
          val === "Yes" ? settds(true) : settds(false);
        }}
      />
      {tds && (
        <DropDown
          options={[1, 2, 10]}
          label="TDS Percent"
          value={tds_percent}
          onChange={settds_percent}
        />
      )}
      {category !== "Staff Payment" && tds && tds_percent && amount && (
        <>
          <div className="info">TDS Amount - &#8377;{tds_amount}</div>
          <div className="info">Net (Payable to verdor) - &#8377;{net}</div>
        </>
      )}
      <CustomTextInput
        placeholder="Transaction Number"
        label="Transaction Number"
        value={transaction_no}
        onChange={(e) => settransaction_no(e.target.value)}
      />
      <input
        label="Select File"
        placeholder="Select File"
        type="file"
        onChange={(e) => setFile(e.target.files[0])}
      />
      <Button text="Add Expense" onClick={handleSubmit} />
    </div>
  );
};

const DetailsChild = ({
  selectedItem,
  is_approved,
  setis_approved,
  setSelectedItem,
  fetchExpenses,
  transaction_no,
  status,
  setTransaction_no,
  setStatus,
  setFile,
  expApprover,
}) => {
  const [uploading, setUploading] = useState(false);
  const [message, setMessage] = useState("");

  console.log("Expense approver is ", expApprover);

  const formatDateAndTime = (timestamp) => {
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleDateString(); // e.g., "12/24/2023"
    const formattedTime = date.toLocaleTimeString(); // e.g., "12:32:35 AM"
    return `${formattedDate} ${formattedTime}`;
  };
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleDateString(); // e.g., "12/24/2023"
    return `${formattedDate}`;
  };

  const handleFileChange = (event) => {
    if (event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }
  };

  const approveExpense = async (expenseId) => {
    const tokens = JSON.parse(localStorage.getItem("ERPTokens")); // Retrieve the tokens stored in local storage

    try {
      const response = await axios.patch(
        `${BE_URL}/api/approve-expense/${expenseId}`,
        {},
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` }, // Using the accessToken for authorization
        }
      );

      if (response.data) {
        setis_approved(true);
        console.log("Expense Approved:", response.data);
        alert("Expense approved successfully!");
      }
    } catch (error) {
      console.error("Error approving expense:", error);
      alert("Failed to approve expense.");
    }
  };

  return (
    <div className="dc">
      <div className="dc-textfull">
        <div className="dc-text-label">Approval Status</div>
        <div className="dc-text-data">
          {is_approved ? "Approved" : "Not Approved"}
        </div>
      </div>
      <div className="dc-text">
        <div className="dc-text-label">Name</div>
        <div className="dc-text-data">{selectedItem.name}</div>
      </div>
      <div className="dc-text">
        <div className="dc-text-label">Categoty</div>
        <div className="dc-text-data">{selectedItem.category}</div>
      </div>
      <div className="dc-text">
        <div className="dc-text-label">Account</div>
        <div className="dc-text-data">{selectedItem.account}</div>
      </div>
      <div className="dc-text">
        <div className="dc-text-label">Department</div>
        <div className="dc-text-data">{selectedItem.department}</div>
      </div>
      <div className="dc-text">
        <div className="dc-text-label">Expense Date</div>
        <div className="dc-text-data">{formatDate(selectedItem.date)}</div>
      </div>

      <div className="dc-text">
        <div className="dc-text-label">Amount</div>
        <div className="dc-text-data">&#8377; {selectedItem.amount}</div>
      </div>
      {selectedItem.gst_percent && (
        <div className="dc-text">
          <div className="dc-text-label">GST Percent</div>
          <div className="dc-text-data"> {selectedItem.gst_percent} &#x25;</div>
        </div>
      )}
      {selectedItem.cgst && (
        <div className="dc-text">
          <div className="dc-text-label">CGST</div>
          <div className="dc-text-data">&#8377; {selectedItem.cgst}</div>
        </div>
      )}
      {selectedItem.sgst && (
        <div className="dc-text">
          <div className="dc-text-label">SGST</div>
          <div className="dc-text-data">&#8377; {selectedItem.sgst}</div>
        </div>
      )}
      {selectedItem.pt && (
        <div className="dc-text">
          <div className="dc-text-label">PT</div>
          <div className="dc-text-data">&#8377; {selectedItem.pt}</div>
        </div>
      )}
      {selectedItem.pf_income && (
        <div className="dc-text">
          <div className="dc-text-label">PF Income</div>
          <div className="dc-text-data">&#8377; {selectedItem.pf_income}</div>
        </div>
      )}
      {selectedItem.pf_deduction && (
        <div className="dc-text">
          <div className="dc-text-label">PF Deduction</div>
          <div className="dc-text-data">
            &#8377; {selectedItem.pf_deduction}
          </div>
        </div>
      )}
      {selectedItem.esi && (
        <div className="dc-text">
          <div className="dc-text-label">ESI</div>
          <div className="dc-text-data">&#8377; {selectedItem.esi}</div>
        </div>
      )}
      {selectedItem.tds && (
        <div className="dc-text">
          <div className="dc-text-label">TDS</div>
          <div className="dc-text-data">
            {" "}
            {selectedItem.tds ? "Yes" : " No"}
          </div>
        </div>
      )}
      {selectedItem.tds_percent && (
        <div className="dc-text">
          <div className="dc-text-label">TDS Percent</div>
          <div className="dc-text-data">{selectedItem.tds_percent}</div>
        </div>
      )}
      {selectedItem.tds && (
        <div className="dc-text">
          <div className="dc-text-label">TDS Amount</div>
          <div className="dc-text-data">&#8377; {selectedItem.tds_amount}</div>
        </div>
      )}
      <div className="dc-text">
        <div className="dc-text-label">Net Amount</div>
        <div className="dc-text-data">
          <strong> &#8377; {selectedItem.net_amount} </strong>
        </div>
      </div>
      {selectedItem.month && (
        <div className="dc-text">
          <div className="dc-text-label">Month</div>
          <div className="dc-text-data"> {selectedItem.month}</div>
        </div>
      )}
      {selectedItem.year && (
        <div className="dc-text">
          <div className="dc-text-label">Year</div>
          <div className="dc-text-data">&#8377; {selectedItem.year}</div>
        </div>
      )}
      {selectedItem.leaves && (
        <div className="dc-text">
          <div className="dc-text-label">Leaves</div>
          <div className="dc-text-data">&#8377; {selectedItem.leaves}</div>
        </div>
      )}

      <div className="dc-text">
        <div className="dc-text-label">Remarks</div>
        <div className="dc-text-data">{selectedItem.remarks}</div>
      </div>
      <div className="dc-text">
        <div className="dc-text-label">Payment Method</div>
        <div className="dc-text-data">{selectedItem.payment_method}</div>
      </div>
      <div className="dc-text">
        <div className="dc-text-label">Transaction Number</div>
        <div className="dc-text-data">{selectedItem.transaction_no}</div>
      </div>
      <div className="dc-text">
        <div className="dc-text-label">Created By</div>
        <div className="dc-text-data">{selectedItem.created_by}</div>
      </div>
      <div className="dc-text">
        <div className="dc-text-label">Created At</div>
        <div className="dc-text-data">
          {formatDateAndTime(selectedItem.created_at)}
        </div>
      </div>
      <div className="dc-input">
        <CustomTextInput
          value={transaction_no}
          label={`Transaction No`}
          placeholder={`Transaction No`}
          onChange={(e) => setTransaction_no(e.target.value)}
        />
      </div>
      <div className="dc-input">
        <ObjectDropDown
          label="Status"
          options={["Pending", "Done"].map((item) => ({
            label: item,
            value: item,
          }))}
          value={status}
          setId={setStatus}
          setValue={setStatus}
        />
      </div>
      {selectedItem.receipt_url ? (
        <>
          {selectedItem.receipt_url &&
            (selectedItem.receipt_url.endsWith(".jpg") ||
              selectedItem.receipt_url.endsWith(".png")) && (
              <div className="dc-image">
                <div className="dc-image-label">Preview</div>
                <div className="dc-image-data">
                  <img src={selectedItem.receipt_url} alt="Receipt Preview" />
                </div>
              </div>
            )}
          <div className="dc-text">
            <div className="dc-text-label">Receipt</div>
            <div className="dc-text-data">
              <a href={selectedItem.receipt_url}>Download</a>
            </div>
          </div>
        </>
      ) : (
        <div className="dc-textfull">
          <div className="dc-text-label">Upload Receipt</div>

          <input type="file" onChange={handleFileChange} />
          {/* <Button
            onClick={handleFileUpload}
            disabled={uploading}
            type={`primary`}
            text={uploading ? "Uploading..." : "Upload File"}
          ></Button> */}
        </div>
      )}
      {expApprover?.can_read && !is_approved ? (
        <div className="dc-text">
          <Button
            text={`Approve`}
            type={`Primary`}
            onClick={() => approveExpense(selectedItem.id)}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
