import React, { useState } from "react";
import axios from "axios";
import "./Results.scss";
import DropDown from "../../components/DropDown/DropDown";
import CustomTextInput from "../../components/CustomTextInput/CustomTextInput";
import TextField from "../../components/TextField/TextField";
import Button from "../../components/Button/Button";

const Results = () => {
  const [classNumber, setClassNumber] = useState("");
  const [rollNo, setRollNo] = useState("");
  const [results, setResults] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const fetchResults = async () => {
    setFetching(true);
    setErrorMessage(""); // Reset error message on new fetch
    try {
      const { data, status } = await axios.get(
        // `http://localhost:8000/api/general/results`,
        `https://server.classerp.in/api/general/results`,
        { params: { classNumber, rollNo } }
      );

      if (status === 204) {
        alert("Result is not out yet. Please check again later.");
      } else if (status === 404) {
        alert(
          "Roll number not found. Please check the roll number and try again."
        );
      } else if (status === 200) {
        alert("Your result has been sent to your email.");
      }
    } catch (error) {
      console.error("Failed to fetch results:", error);
      setErrorMessage("Failed to fetch results. Please try again later.");
    } finally {
      setFetching(false);
    }
  };

  const motivationalQuotes = [
    "The future belongs to those who believe in the beauty of their dreams. - Eleanor Roosevelt",
    "Education is the passport to the future, for tomorrow belongs to those who prepare for it today. - Malcolm X",
    "Success is not the key to happiness. Happiness is the key to success. If you love what you are doing, you will be successful. - Albert Schweitzer",
  ];

  const randomQuote =
    motivationalQuotes[Math.floor(Math.random() * motivationalQuotes.length)];

  return (
    <div className="resultsPage">
      <div className="resultsPage-title">
        Welcome to Chaitanyas Academy Results Page
      </div>
      <p>{randomQuote}</p>
      <div className="resultsPage-form">
        <DropDown
          options={[6, 7, 8, 9, 10, 11, 12]}
          // type="text"
          placeholder="Class Number"
          value={classNumber}
          onChange={(e) => setClassNumber(e)}
        />
        <CustomTextInput
          type="text"
          placeholder="Roll Number"
          value={rollNo}
          onChange={(e) => setRollNo(e.target.value)}
        />
        <Button
          onClick={fetchResults}
          disabled={fetching}
          text={fetching ? "Fetching..." : "Get Result"}
        ></Button>
      </div>
      {results.length > 0 ? (
        <div className="resultsDisplay">
          <h2>Results:</h2>
          <div className="resultsList">
            {results.map((result, index) => (
              <div key={index} className="resultItem">
                {/* <div>Exam Date: {result.exam_date}</div> */}
                {result.marks ? (
                  <div>
                    Marks:{" "}
                    {result.marks ||
                      `${result.part1 + result.part2 + result.part3} (P1: ${
                        result.part1
                      }, P2: ${result.part2}, P3: ${result.part3})`}
                  </div>
                ) : (
                  <>
                    {result.part1 || result.part2 || result.part3 ? (
                      <>
                        {result.part1 !== undefined && (
                          <div>Part 1: {result.part1}</div>
                        )}
                        {result.part2 !== undefined && (
                          <div>Part 2: {result.part2}</div>
                        )}
                        {result.part3 !== undefined && (
                          <div>Part 3: {result.part3}</div>
                        )}
                      </>
                    ) : (
                      <div>Result not out yet.</div>
                    )}
                  </>
                )}
                {result.scholarship_amt && (
                  <div>Scholarship Amount: {result.scholarship_amt}</div>
                )}
                {result.percent && <div>Percent: {result.percent}%</div>}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="resultsDisplay">
          Enter your class number and roll number to see your results.
        </div>
      )}
    </div>
  );
};

export default Results;
