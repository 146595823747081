// import "./EnquiryDetails.scss";

import React, { useEffect, useRef, useState } from "react";
import axios from "axios";

import TextField from "../../components/TextField/TextField";
import DropDown from "../../components/DropDown/DropDown";

import { ReactComponent as Seperator } from "../../assets/images/enquirySeperator.svg";
import Button from "../../components/Button/Button";
import { useNavigate, useParams } from "react-router-dom";

import { Link } from "react-router-dom";
import Loader from "../../components/Loader/Loader";

import { jwtDecode } from "jwt-decode";
import { useSelector } from "react-redux";

/////////////////////////////////////////
//Enquiry Page
const EnquiryDetails = () => {
  const { id } = useParams();

  //RBAC code block
  const allPermissions = useSelector((state) => state.permissions);
  const permissions = allPermissions
    .find((p) => p.name === "Admissions")
    ?.dropdowns.find((dd) => dd.name === "Enquiry List")?.permissions;

  const enqcomments = allPermissions
    .find((p) => p.name === "Admissions")
    ?.dropdowns.find(
      (dd) => dd.name === "Enquiry Councellor Comments"
    )?.permissions;
  //RBAC Code block ends.

  const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
  const decodedToken = tokens ? jwtDecode(tokens.accessToken) : null;
  const roleId = decodedToken ? decodedToken.role_id : null;

  const canView = (allowedRoles) => {
    return allowedRoles.includes(roleId);
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [whatsapp, setWhatsapp] = useState(null);
  const [alternateNo, setalternateNo] = useState(null);
  const [place, setPlace] = useState(null);
  const [school, setSchool] = useState(null);
  const [admclass, setAdmclass] = useState("Want Admission for Class");
  const [reference, setReference] = useState(null);

  //Other Exams
  const [otherExams, setOtherExams] = useState(null);
  const [exam1, setexam1] = useState(null);
  const [exam2, setexam2] = useState(null);
  const [exam3, setexam3] = useState(null);
  //   const [exam4, setexam4] = useState(null);
  const [exam1Marks, setexam1Marks] = useState(null);
  const [exam2Marks, setexam2Marks] = useState(null);
  const [exam3Marks, setexam3Marks] = useState(null);
  //   const [exam4Marks, setexam4Marks] = useState(null);

  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [branch, setBranch] = useState(null);

  const classOptions = [
    "Class 6th",
    "Class 7th",
    "Class 8th",
    "Class 9th",
    "Class 10th",
    "Class 11th",
    "Class 12th",
    "Neet Repeater",
  ];

  const refOptions = ["Facebook", "Newspaper", "Friend", "Website", "Others"];
  const branches = ["Baramati", "Pune", "Nagar", "Ranchi"];
  const examOptions = [
    "MHCET",
    "NTSE",
    "8th Std Scholarship exam",
    "5th Std Scholarship exam",
    "Olympiad",
    "Other",
  ];
  const examBool = ["Yes", "No"];

  const [error, setError] = useState("");
  const [invalidFields, setInvalidFields] = useState({});

  useEffect(() => {
    console.log("Useeffect render");
    setIsLoading(true);
    const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
    axios
      // .get(`http://localhost:8000/getEnquiry/${id}`, {
      .get(`https://server.classerp.in/getEnquiry/${id}`, {
        headers: {
          Authorization: `Bearer ${tokens.accessToken}`,
        },
      })
      .then((response) => {
        const enquiryData = response.data;
        setBranch(enquiryData.branch);
        setName(enquiryData.full_name);
        setEmail(enquiryData.email);
        setWhatsapp(enquiryData.whatsapp);
        setalternateNo(enquiryData.alternate_no);
        setPlace(enquiryData.place);
        setSchool(enquiryData.school_name);
        setAdmclass(enquiryData.admission_for_class);
        setReference(enquiryData.reference);
        // Set other exams details.
        setOtherExams(enquiryData.otherExams);
        setexam1(enquiryData.exam1);
        setexam2(enquiryData.exam2);
        setexam3(enquiryData.exam3);
        setexam1Marks(enquiryData.exam1Marks);
        setexam2Marks(enquiryData.exam2Marks);
        setexam3Marks(enquiryData.exam3Marks);
        // Add any other fields as needed
      })
      .catch((error) => {
        console.error("Error fetching enquiry:", error);
        alert("Failed to fetch form data.");
      })
      .finally(() => {
        fetchComments();
        setIsLoading(false);
      });
  }, [id]);

  const formatDateAndTime = (timestamp) => {
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleDateString(); // e.g., "12/24/2023"
    const formattedTime = date.toLocaleTimeString(); // e.g., "12:32:35 AM"
    return `${formattedDate} ${formattedTime}`;
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleDateString(); // e.g., "12/24/2023"
    // const formattedTime = date.toLocaleTimeString(); // e.g., "12:32:35 AM"
    return `${formattedDate}`;
  };

  const refreshToken = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      // const response = await fetch("http://localhost:8000/refreshToken", {
      const response = await fetch("https://server.classerp.in/refreshToken", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token: tokens.refreshToken }),
      });

      if (!response.ok) {
        throw new Error("Failed to refresh token");
      }

      const newTokens = await response.json();
      localStorage.setItem("ERPTokens", JSON.stringify(newTokens));
      return newTokens;
    } catch (error) {
      console.error("Error refreshing token:", error);
      alert("User loggod out. Please login again");
      // navigate("/login");
      return null;
    }
  };

  const fetchComments = async () => {
    if (isLoading) return;
    setIsLoading(true);

    const enquiry_id = id;
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.get(
        `https://server.classerp.in/getEnquiryComments/${enquiry_id}`,
        // `http://localhost:8000/getEnquiryComments/${enquiry_id}`,
        {
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`,
          },
        }
      );
      setComments(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newTokens = await refreshToken();
        if (newTokens) {
          fetchComments(); // Retry with new token
        }
      } else {
        alert("Error fetching the comments.");
        console.error("Error fetching comments:", error);
      }
    }
    setIsLoading(false);
  };

  const handleAddComment = async () => {
    if (!window.confirm("Are you sure you want to post the comment?")) {
      return;
    }
    if (isLoading) return;
    setIsLoading(true);
    const enquiry_id = id;
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));

      const obj = jwtDecode(tokens.accessToken);
      const { user_id } = obj;

      await axios.post(
        // `http://localhost:8000/addEnquiryComment`,
        `https://server.classerp.in/addEnquiryComment`,
        { enquiry_id, text: newComment, user_id: user_id },
        {
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`,
          },
        }
      );
      setNewComment("");
      fetchComments(); // Refresh comments
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newTokens = await refreshToken();
        if (newTokens) {
          handleAddComment(); // Retry with new token
        }
      } else {
        console.error("Error posting comment:", error);
      }
    }
    setIsLoading(false);
  };

  const validateForm = () => {
    const errors = {};
    if (!branch) errors.branch = true;
    if (!name) errors.name = true;
    if (!email) errors.email = true;
    if (!whatsapp) errors.whatsapp = true;
    if (!alternateNo) errors.alternateNo = true;
    if (!place) errors.place = true;
    if (!school) errors.school = true;
    if (admclass === "Want Admission for Class") errors.admclass = true;
    if (!reference) errors.reference = true;

    setInvalidFields(errors);
    if (Object.keys(errors).length === 0) {
      setError("");
      return true;
    } else {
      setError("Please fill in the fields marked in red.");
      return false;
    }
  };

  const handleEdit = async () => {
    if (validateForm()) {
      setIsSubmitting(true);
      setIsLoading(true);

      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const enquiryData = {
        branch,
        name,
        email,
        whatsapp,
        alternateNo,
        place,
        school,
        admclass,
        reference,
        otherExams,
        exam1,
        exam2,
        exam3,
        exam1Marks,
        exam2Marks,
        exam3Marks,
      };

      axios
        // .put(`http://localhost:8000/enquiries/${id}`, enquiryData, {
        .put(`https://server.classerp.in/enquiries/${id}`, enquiryData, {
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`,
          },
        })
        .then((response) => {
          console.log("Enquiry updated:", response.data);
          alert("Form data updated successfully");

          // Handle success (maybe navigate back or show a success message)
        })
        .catch((error) => {
          console.error("Error updating enquiry:", error);
          setError("There was a problem updating your form. Please try again.");
          alert("Failed to update the form.");
          // Handle token expiry
          if (error.response && error.response.status === 401) {
            // Refresh token logic here
          }
        })
        .finally(() => {
          setIsSubmitting(false);
          setIsLoading(false);
        });
    }
  };

  const printDivRef = useRef();

  const handlePrint = () => {
    console.log("printing");
    const printContents = printDivRef.current.innerHTML;
    const originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  };

  return (
    <div className="enquiry">
      {isLoading && <Loader />}

      {permissions?.can_read && (
        <>
          <div ref={printDivRef} className="enquiry-right">
            <div className="enquiry-right-title">Enquiry Form</div>
            <div className="enquiry-right-sep">
              <Seperator />
            </div>
            <div className="enquiry-right-fields">
              <div
                className={`enquiry-right-fields-field ${
                  invalidFields.branch ? "invalid" : ""
                }`}
              >
                <DropDown
                  invalid={invalidFields.branch}
                  options={branches}
                  label={`Branch`}
                  value={branch}
                  onChange={setBranch}
                />
              </div>
              <div
                className={`enquiry-right-fields-field ${
                  invalidFields.name ? "invalid" : ""
                }`}
              >
                <TextField
                  placeholder={`Full Name`}
                  value={name}
                  onChange={setName}
                />
              </div>
              <div
                className={`enquiry-right-fields-field ${
                  invalidFields.email ? "invalid" : ""
                }`}
              >
                <TextField
                  placeholder={`Email`}
                  value={email}
                  onChange={setEmail}
                />
              </div>
              <div
                className={`enquiry-right-fields-field ${
                  invalidFields.whatsapp ? "invalid" : ""
                }`}
              >
                <TextField
                  placeholder={`WhatsApp`}
                  value={whatsapp}
                  onChange={setWhatsapp}
                />
              </div>
              <div
                className={`enquiry-right-fields-field ${
                  invalidFields.alternateNo ? "invalid" : ""
                }`}
              >
                <TextField
                  placeholder={`Alternate No.`}
                  value={alternateNo}
                  onChange={setalternateNo}
                />
              </div>
              <div
                className={`enquiry-right-fields-field ${
                  invalidFields.place ? "invalid" : ""
                }`}
              >
                <TextField
                  placeholder={`Place`}
                  value={place}
                  onChange={setPlace}
                />
              </div>
              <div
                className={`enquiry-right-fields-field ${
                  invalidFields.school ? "invalid" : ""
                }`}
              >
                <TextField
                  placeholder={`School Name`}
                  value={school}
                  onChange={setSchool}
                />
              </div>
              <div
                className={`enquiry-right-fields-field ${
                  invalidFields.admclass ? "invalid" : ""
                }`}
              >
                <DropDown
                  invalid={invalidFields.admclass}
                  options={classOptions}
                  label={`Want Admission for Class`}
                  value={admclass}
                  onChange={setAdmclass}
                />
              </div>
              <div
                className={`enquiry-right-fields-field ${
                  invalidFields.reference ? "invalid" : ""
                }`}
              >
                <DropDown
                  invalid={invalidFields.reference}
                  options={refOptions}
                  label={`Reference`}
                  value={reference}
                  onChange={setReference}
                />
              </div>

              <div className="enquiry-right-fields-field">
                <DropDown
                  options={examBool}
                  label={`Other Competitive Exams`}
                  value={otherExams}
                  onChange={setOtherExams}
                />
              </div>
              <div className="enquiry-right-fields-field"></div>
              {otherExams === "Yes" ? (
                <>
                  <div className="enquiry-right-fields-field">
                    <DropDown
                      options={examOptions}
                      label={`Select Exam`}
                      value={exam1}
                      onChange={setexam1}
                    />
                  </div>
                  <div className="enquiry-right-fields-field">
                    <TextField
                      placeholder={`Exam Marks`}
                      value={exam1Marks}
                      onChange={setexam1Marks}
                    />
                  </div>

                  <div className="enquiry-right-fields-field">
                    <DropDown
                      options={examOptions}
                      label={`Select Exam`}
                      value={exam2}
                      onChange={setexam2}
                    />
                  </div>
                  <div className="enquiry-right-fields-field">
                    <TextField
                      placeholder={`Exam Marks`}
                      value={exam2Marks}
                      onChange={setexam2Marks}
                    />
                  </div>

                  <div className="enquiry-right-fields-field">
                    <DropDown
                      options={examOptions}
                      label={`Select Exam`}
                      value={exam3}
                      onChange={setexam3}
                    />
                  </div>
                  <div className="enquiry-right-fields-field">
                    <TextField
                      placeholder={`Exam Marks`}
                      value={exam3Marks}
                      onChange={setexam3Marks}
                    />
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
            {error && <div className="enquiry-right-error">{error}</div>}
            <div className="enquiry-right-button">
              {permissions?.can_update && (
                <Button text={`Edit`} onClick={handleEdit} />
              )}
              {/* <Button text={`Edit`} onClick={handleEdit} /> */}
              <Button text={`Print`} onClick={handlePrint} />
            </div>
          </div>
          {enqcomments?.can_read && (
            <div className="enquiry-left">
              <div className="asd-comments">
                <div className="asd-comments-heading">Counsellor Comments</div>
                {comments.length ? "" : "No comments yet."}
                {comments.map((comment, index) => (
                  <div key={index} className="asd-comments-card">
                    <div className="asd-comments-card-data">
                      <span className="asd-comments-card-data-user">
                        {comment.user_name}
                      </span>{" "}
                      <span className="asd-comments-card-data-date">
                        {formatDateAndTime(comment.timestamp)}
                        {/* {new Date(comment.timestamp).toLocaleDateString()} */}
                      </span>
                    </div>
                    <div className="asd-comments-card-comment">
                      {comment.text}
                    </div>
                  </div>
                ))}
                {enqcomments?.can_create && (
                  <div className="asd-comments-add">
                    <textarea
                      value={newComment}
                      onChange={(e) => setNewComment(e.target.value)}
                      placeholder="Add a comment"
                    />
                    <Button text={"Post Comment"} onClick={handleAddComment} />
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default EnquiryDetails;
