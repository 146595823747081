import React, { useEffect, useState, useCallback } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./InstallmentsDisplay.scss";
import Button from "../Button/Button";
import CustomTextInput from "../CustomTextInput/CustomTextInput";
import CustomDropDown from "../CustomDropDown/CustomDropDown";
import CustomNumberInput from "../CustomNumberInput/CustomNumberInput";

const InstallmentsDisplay = ({
  title,
  feeInstallments, // List of fee installments
  accounts, // List of accounts
  setInstallments,
  fee = 0,
  setfee,
  initialInstallments = [], // Initial data for installments
}) => {
  const [localInstallments, setLocalInstallments] = useState([]);

  useEffect(() => {
    // Whenever initialInstallments changes, update localInstallments
    if (initialInstallments && initialInstallments.length > 0) {
      // Reset localInstallments if initialInstallments is empty

      setLocalInstallments(initialInstallments);

      const mappedInstallments = initialInstallments.map((inst, index) => {
        // Find the corresponding feeInstallment based on the feeInstallmentId
        const feeInstallment = feeInstallments.find(
          (feeInst) => feeInst.id === inst.installments_id
        );

        // Apply the amount and account from feeInstallment if found, otherwise use existing values
        return {
          installment_number: index + 1, // Set the installment_number
          feeInstallmentId: inst.installments_id || inst.feeInstallmentId,
          name: inst.name,
          amount: feeInstallment ? feeInstallment.amount : inst.amount,
          account: feeInstallment ? feeInstallment.account_id : inst.account_id,
          dueDate:
            formatDateForInput(inst.due_date || inst.dueDate) ||
            new Date().toISOString().split("T")[0],
        };
      });

      setLocalInstallments(mappedInstallments);
      // setInstallments(mappedInstallments);
    }
  }, [initialInstallments, feeInstallments]);

  // Handle fee installment selection and update total fee

  const handleInstallmentChange = (index, feeInstallmentId) => {
    const selectedInstallment = feeInstallments.find(
      (inst) => inst.id === Number(feeInstallmentId)
    );

    const updatedInstallments = [...localInstallments];

    updatedInstallments[index] = {
      ...updatedInstallments[index], // Retain other properties
      feeInstallmentId: selectedInstallment?.id || null,
      name: selectedInstallment?.name || "",
      amount: selectedInstallment?.amount || 0,
      account: selectedInstallment?.account_id || "",
    };

    // Update installment_number for all installments
    updatedInstallments.forEach((inst, idx) => {
      inst.installment_number = idx + 1;
    });

    setLocalInstallments(updatedInstallments);
    setInstallments(updatedInstallments);
    updateTotalFee(updatedInstallments);
  };

  // Update due date of installment
  const handleDueDateChange = (index, value) => {
    const updatedInstallments = [...localInstallments];
    updatedInstallments[index].dueDate = value;
    setLocalInstallments(updatedInstallments);
    setInstallments(updatedInstallments);
  };

  // Add a new installment row
  const addInstallmentRow = useCallback(() => {
    const newInstallment = {
      installment_number: localInstallments.length + 1, // Set the installment_number
      feeInstallmentId: null,
      name: "",
      amount: 0,
      dueDate: new Date().toISOString().split("T")[0],
      account: "",
    };

    const updatedInstallments = [...localInstallments, newInstallment];

    // Update installment_number for all installments
    updatedInstallments.forEach((inst, idx) => {
      inst.installment_number = idx + 1;
    });

    setLocalInstallments(updatedInstallments);
    // setInstallments(updatedInstallments);
    updateTotalFee(updatedInstallments);
  }, [localInstallments, setInstallments]);

  // Delete an installment row
  const deleteInstallmentRow = useCallback(
    (indexToDelete) => {
      const updatedInstallments = localInstallments.filter(
        (_, index) => index !== indexToDelete
      );

      // Update installment_number for all installments
      updatedInstallments.forEach((inst, idx) => {
        inst.installment_number = idx + 1;
      });

      setLocalInstallments(updatedInstallments);
      setInstallments(updatedInstallments);
      updateTotalFee(updatedInstallments);
    },
    [localInstallments, setInstallments]
  );

  // Update total fee when installments change
  const updateTotalFee = (installmentsList) => {
    const total = installmentsList.reduce(
      (sum, inst) => sum + (Number(inst.amount) || 0),
      0
    );
    setfee(total);
  };

  // Handle drag-and-drop functionality
  const onDragEnd = useCallback(
    (result) => {
      if (!result.destination) return;
      const updatedInstallments = Array.from(localInstallments);
      const [movedItem] = updatedInstallments.splice(result.source.index, 1);
      updatedInstallments.splice(result.destination.index, 0, movedItem);

      // Update installment_number for all installments
      updatedInstallments.forEach((inst, idx) => {
        inst.installment_number = idx + 1;
      });

      setLocalInstallments(updatedInstallments);
      setInstallments(updatedInstallments);
    },
    [localInstallments, setInstallments]
  );

  // Format date to 'yyyy-MM-dd' for input elements
  const formatDateForInput = (isoDateString) => {
    if (!isoDateString) return "";
    const date = new Date(isoDateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  return (
    <div className="installments-display">
      <h2>{title}</h2>

      {/* Total Fee */}
      <div className="installments-display-misc">
        <div className="installments-display-misc-inst">
          Drag up and down to rearrange.
        </div>
        <div className="installments-display-misc-fee">
          <strong>Total Fee:</strong> Rs {fee}/-
        </div>
      </div>

      <div className="installments-list">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="installments-list">
            {(provided) => (
              <div
                className="installments-items"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {localInstallments.map((inst, index) => (
                  <Draggable
                    key={index}
                    draggableId={`item-${index}`}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        className="installment-item"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {/* Dropdown for selecting fee installment */}
                        <div className="installment-item-options">
                          <div className="installment-select">
                            <CustomDropDown
                              label="Select Fee Installment"
                              data={feeInstallments}
                              value={inst.feeInstallmentId || ""}
                              onChange={(e) =>
                                handleInstallmentChange(index, e)
                              }
                              cleanable={false}
                            />
                          </div>

                          {/* Due Date */}
                          <div className="installment-due-date">
                            <CustomTextInput
                              label="Due Date"
                              type="date"
                              value={inst.dueDate}
                              onChange={(e) =>
                                handleDueDateChange(index, e.target.value)
                              }
                            />
                          </div>

                          {/* Delete button */}
                          <Button
                            type="minired"
                            text="X"
                            onClick={() => deleteInstallmentRow(index)}
                          />
                        </div>

                        {/* Display amount and account */}
                        {inst.feeInstallmentId && (
                          <div className="installment-item-values">
                            <div>
                              <strong>Amount:</strong> Rs {inst.amount}
                            </div>
                            <div>
                              <strong>Account:</strong>{" "}
                              {accounts.find((acc) => acc.id === inst.account)
                                ?.name || ""}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      {/* Add Installment button */}
      <div className="installments-button">
        <Button
          type="mini"
          text="Add Installment"
          onClick={addInstallmentRow}
        />
      </div>
    </div>
  );
};

export default InstallmentsDisplay;

export const ComponentsDisplay = ({
  title,
  fee,
  installments,
  setInstallments,
  accounts,
}) => {
  console.log(accounts);
  accounts = accounts || [];

  // Handle changes in installment inputs
  const handleInstallmentChange = (index, field, value) => {
    const newInstallments = (installments || []).map((inst, idx) => {
      if (idx === index) {
        return { ...inst, [field]: value };
      }
      return inst;
    });
    setInstallments(newInstallments);
  };

  const handleAccountChange = (index, value) => {
    const acc = accounts.filter((item) => item.id === value);
    const newInstallments = (installments || []).map((inst, idx) => {
      if (idx === index) {
        return { ...inst, account: value };
      }
      return inst;
    });
    setInstallments(newInstallments);
  };

  // Function to add a new installment row
  const addInstallmentRow = () => {
    const newInstallment = {
      component_number: (installments || []).length + 1,
      component_name: "",
      amount: "",
    };
    setInstallments([...(installments || []), newInstallment]);
  };

  // Function to handle removal of an installment
  const removeInstallmentRow = (indexToRemove) => {
    const filteredInstallments = (installments || []).filter(
      (_, index) => index !== indexToRemove
    );
    setInstallments(filteredInstallments);
  };

  // Calculate total of installments
  const totalInstallments = (installments || []).reduce(
    (acc, curr) => acc + Number(curr.amount || 0),
    0
  );

  return (
    <div className="components">
      <div className="components-title">{title}</div>
      <div className="components-total">
        <strong>Total Fee:</strong> Rs {fee}/-
      </div>
      {installments &&
        installments.map((inst, index) => (
          <div key={index} className="components-row">
            <div className="components-row-values">
              <div className="components-row-values-name">
                <CustomTextInput
                  label="Component Name"
                  placeholder="Component Name"
                  value={inst.component_name}
                  onChange={(e) =>
                    handleInstallmentChange(
                      index,
                      "component_name",
                      e.target.value
                    )
                  }
                />
              </div>
              <div className="components-row-values-amount">
                <CustomNumberInput
                  label="Amount"
                  placeholder="Amount"
                  type="number"
                  value={inst.amount}
                  onChange={(e) => handleInstallmentChange(index, "amount", e)}
                />
              </div>

              <div className="components-row-remove">
                <Button
                  type="minired"
                  text="X"
                  onClick={() => removeInstallmentRow(index)}
                />
              </div>
            </div>
          </div>
        ))}
      {/* <Button text="Add Installment" onClick={addInstallmentRow} /> */}
      <div className="components-button">
        <Button
          type={`mini`}
          onClick={addInstallmentRow}
          text={`Add Component`}
        />
      </div>
      {installments.length > 0 && totalInstallments !== Number(fee) && (
        <div style={{ color: "red" }}>
          Total of components (Rs {totalInstallments}/-) does not match the
          total fee.
        </div>
      )}
    </div>
  );
};
